'use strict';

// We need bootstrap for modal
require('bootstrap-sass/assets/javascripts/bootstrap/modal');

$(document).ready(function() {
  // modal
  var modal = $('#modalLogin');
  var modalForgot = $('#modalForgot');
  var forgotBtn = $('#forgotBtn');

  forgotBtn.click(function(e) {
    e.preventDefault();
    modal.hide();
    modalForgot.on('hide.bs.modal', function(e) {
      e.preventDefault();
    });
    modalForgot.modal();
  });

  modal.modal({
    backdrop: 'static',
    keyboard: false
  });
  modal.on('hide.bs.modal', function(e) {
    e.preventDefault();
  });

  // case login with code labo
  var forms = $('form');
  forms.on('submit', function(e) {
    e.preventDefault();
    e.stopPropagation();
    forms.off();
    var login = $(this).find('.username');
    if (login.val().length === 6 && parseInt(login.val())) {
      login.val(login.val()+'00');
    }
    
    $(this).submit();
  });
});